const socialLinks = [
    {
        icon: 'facebook',
        url: 'https://www.facebook.com/'
    },
    {
        icon: 'twitter',
        url: 'https://twitter.com/'
    },
    {
        icon: 'linkedin',
        url: '/https://www.linkedin.com/'
    },
    {
        icon: 'instagram',
        url: 'https://instagram.com/'
    }
];

export default socialLinks;
