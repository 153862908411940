import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

import { parsePath } from 'utils/helpers';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    appTitle: {
        display: 'inline',
        color: theme.palette.text.primary,
        marginLeft: '10px',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.3rem'
        }
    },
    root: (props) => ({
        maxWidth: '350px',
        position: 'relative',
        zIndex: '1199',
        transform: 'scale(.8)',
        '& a': {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            '& svg': {
                '& path': {
                    fill: props.color === 'white' ? '#FFFFFF' : theme.palette.primary.main
                }
            }
        },
        [theme.breakpoints.up('md')]: {
            transform: 'scale(1)'
        }
    })
}));

export default function Logo({ className, style }) {
    const color = useSelector(({ ui }) => ui.headerSettings?.logoColor);

    const internalClasses = useStyles({ color });

    return (
        <div className={clsx(internalClasses.root, className, 'main-logo')} style={style}>
            <Link to={parsePath()}>
                <svg width="191" height="37" viewBox="0 0 191 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_762_431)">
                        <path d="M5.8142 29.9939V30.5129C5.8142 31.9443 6.60543 33.0167 8.30829 33.0167C10.2864 33.0167 11.671 31.4253 11.671 28.1647V25.6954H11.4302C10.8768 26.4084 10.1678 26.9839 9.35812 27.3775C8.54841 27.7712 7.65958 27.9724 6.76023 27.9658C3.55662 27.9658 -0.00390625 25.3711 -0.00390625 18.4174C-0.00390625 11.4637 3.55662 8.75223 6.76023 8.75223C7.75141 8.74263 8.7294 8.98107 9.60616 9.44609C10.4829 9.9111 11.2309 10.5881 11.7828 11.4161H11.9806L12.6557 9.15009H16.5259V27.529C16.5259 33.8124 13.1245 36.9952 8.4158 36.9952C3.39322 36.9952 0.860422 34.2103 0.860422 30.8675C0.868573 30.5217 0.89585 30.1767 0.942126 29.8339L5.8142 29.9939ZM11.3528 22.1191V14.56C11.1094 14.0401 10.7316 13.5956 10.2587 13.273C9.78589 12.9504 9.2355 12.7616 8.66521 12.7264C6.94515 12.7264 5.22508 13.881 5.22508 18.4563C5.22508 22.8716 6.96664 23.9873 8.6265 23.9873C9.20644 23.9529 9.76626 23.761 10.2463 23.432C10.7264 23.1031 11.1088 22.6493 11.3528 22.1191Z" />
                        <path d="M20.6104 16.2289C20.535 15.7817 20.4961 15.329 20.4943 14.8754C20.4943 11.1347 22.868 8.75195 27.9679 8.75195C33.7043 8.75195 35.2782 12.1293 35.2782 15.8311V23.9092C35.2782 24.6616 35.5147 25.0205 35.9533 25.0205C36.2239 25.0104 36.4911 24.9563 36.7446 24.8605L36.9424 28.5623C36.0362 28.8709 35.0867 29.0315 34.1301 29.038C31.6016 29.038 31.12 27.4076 31.0468 25.9762H30.849C30.189 26.9405 29.3013 27.7249 28.2657 28.2593C27.23 28.7936 26.0786 29.0612 24.9148 29.038C21.8273 29.038 19.8105 27.3687 19.8105 23.7491C19.8105 20.1296 22.3433 17.3014 30.2556 17.1846V15.5111C30.2556 13.7813 29.4644 12.5272 27.7228 12.5272C25.9039 12.5272 25.0739 13.3229 25.0739 15.0354C25.0739 15.2343 25.1126 15.6322 25.1513 15.9522L20.6104 16.2289ZM26.7037 25.4573C28.0496 25.4573 29.3913 24.5059 30.2642 23.1913V20.1296C25.9125 20.2463 24.7256 21.401 24.7256 23.1135C24.7256 24.5838 25.4351 25.4573 26.7037 25.4573Z" />
                        <path d="M39.875 -0.0175781H45.0352V10.9622H45.1943C45.6892 10.2582 46.3483 9.68707 47.1137 9.2988C47.8792 8.91052 48.7276 8.71697 49.5848 8.73511C52.8701 8.73511 56.2715 11.4768 56.2715 18.837C56.2715 26.0762 52.9475 29.0211 49.5848 29.0211C48.6157 29.0388 47.6572 28.8155 46.7946 28.3712C45.9319 27.9268 45.1917 27.2752 44.6396 26.474H44.4418L43.7709 28.6362H39.9008L39.875 -0.0175781ZM45.0352 23.3345C45.2895 23.83 45.668 24.2503 46.1334 24.5537C46.5987 24.857 47.1347 25.033 47.6884 25.0642C49.5461 25.0642 51.0124 23.555 51.0124 18.7808C51.0124 14.0066 49.4686 12.692 47.6884 12.692C46.6994 12.692 45.6286 13.4098 45.0352 14.4218V23.3345Z" />
                        <path d="M59.6992 9.15039H64.8594V28.6407H59.6992V9.15039Z" />
                        <path d="M69.1891 9.15111H73.0592L73.773 11.3133H73.9278C74.4804 10.513 75.2209 9.86216 76.0835 9.41859C76.9461 8.97502 77.9043 8.75243 78.873 8.77056C82.2744 8.77056 85.5598 11.7112 85.5598 18.9546C85.5598 26.3062 82.1583 29.0566 78.873 29.0566C78.0188 29.0749 77.1736 28.8775 76.4148 28.4824C75.6561 28.0873 75.008 27.507 74.5299 26.7949H74.3321V36.5811H69.1719L69.1891 9.15111ZM74.3493 23.3526C74.6127 23.8408 74.9935 24.2549 75.457 24.5571C75.9204 24.8592 76.4517 25.0398 77.0025 25.0824C78.7827 25.0824 80.3265 23.7332 80.3265 18.959C80.3265 14.1848 78.8988 12.7101 77.0025 12.7101C76.4477 12.737 75.9099 12.9113 75.4438 13.2151C74.9778 13.519 74.6001 13.9416 74.3493 14.4399V23.3526Z" />
                        <path d="M96.5299 8.75195C102.223 8.75195 104.954 12.7261 104.954 18.936C104.954 25.1459 102.223 29.038 96.5299 29.038C90.8365 29.038 88.1016 25.0984 88.1016 18.936C88.1016 12.7737 90.7935 8.75195 96.5299 8.75195ZM99.7335 18.936C99.7335 14.3175 98.5466 12.7261 96.5299 12.7261C94.5131 12.7261 93.322 14.3175 93.322 18.936C93.322 23.4681 94.5088 25.0984 96.5299 25.0984C98.5509 25.0984 99.7335 23.4681 99.7335 18.936Z" />
                        <path d="M108.515 -0.0175781H113.675V28.6233H108.515V-0.0175781Z" />
                        <path d="M117.85 16.2289C117.774 15.7817 117.735 15.329 117.734 14.8754C117.734 11.1347 120.107 8.75195 125.207 8.75195C130.944 8.75195 132.517 12.1293 132.517 15.8311V23.9092C132.517 24.6616 132.758 25.0205 133.193 25.0205C133.463 25.0104 133.73 24.9563 133.984 24.8605L134.182 28.5623C133.277 28.8709 132.329 29.0315 131.374 29.038C128.841 29.038 128.364 27.4076 128.286 25.9762H128.088C127.428 26.9405 126.541 27.7249 125.505 28.2593C124.469 28.7936 123.318 29.0612 122.154 29.038C119.067 29.038 117.05 27.3687 117.05 23.7491C117.05 20.1296 119.583 17.3014 127.495 17.1846V15.5111C127.495 13.7813 126.704 12.5272 124.962 12.5272C123.143 12.5272 122.313 13.3229 122.313 15.0354C122.313 15.2343 122.352 15.6322 122.391 15.9522L117.85 16.2289ZM123.943 25.4573C125.289 25.4573 126.635 24.5059 127.503 23.1913V20.1296C123.152 20.2463 121.965 21.401 121.965 23.1135C121.965 24.5838 122.679 25.4573 123.943 25.4573Z" />
                        <path d="M137.119 9.14981H140.955L141.548 11.9737H141.746C142.576 10.4212 144.081 8.75195 146.653 8.75195C150.093 8.75195 152.505 10.9358 152.505 16.5879V28.6401H147.345V17.0635C147.345 13.6818 146.554 12.7391 145.014 12.7391C143.784 12.7391 142.838 13.6948 142.284 14.9705V28.6531H137.123L137.119 9.14981Z" />
                        <path d="M164.218 8.75195C170.982 8.75195 171.692 13.444 171.692 15.5543C171.7 15.8877 171.674 16.221 171.615 16.5489L166.549 16.6268C166.58 16.4033 166.595 16.1778 166.592 15.9522C166.592 14.7975 166.196 12.7261 164.137 12.7261C162.279 12.7261 161.01 14.2786 161.01 18.936C161.01 22.9145 162.159 25.0984 164.137 25.0984C166.196 25.0984 166.592 23.0313 166.592 21.8377C166.594 21.6121 166.579 21.3867 166.549 21.1631L171.615 21.241C171.672 21.5693 171.698 21.9023 171.692 22.2356C171.692 24.1859 170.982 29.038 164.218 29.038C158.559 29.038 155.79 25.3405 155.79 18.936C155.79 13.3878 158.284 8.75195 164.218 8.75195Z" />
                        <path d="M182.576 8.75195C188.274 8.75195 191.005 12.7261 191.005 18.936C191.005 25.1459 188.274 29.038 182.576 29.038C176.879 29.038 174.152 25.0984 174.152 18.936C174.152 12.7737 176.836 8.75195 182.576 8.75195ZM185.78 18.936C185.78 14.3175 184.593 12.7261 182.576 12.7261C180.56 12.7261 179.373 14.3175 179.373 18.936C179.373 23.4681 180.56 25.0984 182.576 25.0984C184.593 25.0984 185.776 23.4681 185.776 18.936H185.78Z" />
                    </g>
                    <defs>
                        <clipPath id="clip0_762_431">
                            <rect width="191" height="37" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Link>
        </div>
    );
}
